{
    "moving_piston": [],
    "water": [],
    "lava": [],
    "bubble_column": [],
    "air": [],
    "void_air": [],
    "cave_air": [],
    "mangrove_propagule": {
        "hanging=true": [
            7,
            0,
            7,
            9,
            16,
            9
        ],
        "age=0": [
            7,
            13,
            7,
            9,
            16,
            9
        ],
        "age=1": [
            7,
            10,
            7,
            9,
            16,
            9
        ],
        "age=2": [
            7,
            7,
            7,
            9,
            16,
            9
        ],
        "age=3": [
            7,
            3,
            7,
            9,
            16,
            9
        ],
        "age=4": [
            7,
            0,
            7,
            9,
            16,
            9
        ]
    },
    "fern": [
        2,
        0,
        2,
        14,
        13,
        14
    ],
    "dandelion": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "torchflower": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "poppy": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "blue_orchid": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "allium": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "azure_bluet": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "red_tulip": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "orange_tulip": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "white_tulip": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "pink_tulip": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "oxeye_daisy": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "cornflower": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "wither_rose": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "lily_of_the_valley": [
        5,
        0,
        5,
        11,
        10,
        11
    ],
    "brown_mushroom": [
        5,
        0,
        5,
        11,
        6,
        11
    ],
    "red_mushroom": [
        5,
        0,
        5,
        11,
        6,
        11
    ],
    "torch": [
        6,
        0,
        6,
        10,
        10,
        10
    ],
    "soul_fire": [
        0,
        0,
        0,
        16,
        1,
        16
    ],
    "soul_torch": [
        6,
        0,
        6,
        10,
        10,
        10
    ],
    "end_portal": [
        0,
        6,
        0,
        16,
        12,
        16
    ],
    "end_gateway": [],
    "kelp_plant": [
        0,
        0,
        0,
        16,
        16,
        16
    ],
    "dead_tube_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "dead_brain_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "dead_bubble_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "dead_fire_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "dead_horn_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "tube_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "brain_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "bubble_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "fire_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "horn_coral": [
        2,
        0,
        2,
        14,
        15,
        14
    ],
    "dead_tube_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "dead_brain_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "dead_bubble_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "dead_fire_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "dead_horn_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "tube_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "brain_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "bubble_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "fire_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "horn_coral_fan": [
        2,
        0,
        2,
        14,
        4,
        14
    ],
    "bamboo_sapling": [
        4,
        0,
        4,
        12,
        12,
        12
    ],
    "warped_fungus": [
        4,
        0,
        4,
        12,
        9,
        12
    ],
    "warped_roots": [
        2,
        0,
        2,
        14,
        13,
        14
    ],
    "nether_sprouts": [
        2,
        0,
        2,
        14,
        3,
        14
    ],
    "crimson_fungus": [
        4,
        0,
        4,
        12,
        9,
        12
    ],
    "weeping_vines_plant": [
        1,
        0,
        1,
        15,
        16,
        15
    ],
    "twisting_vines_plant": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "crimson_roots": [
        2,
        0,
        2,
        14,
        13,
        14
    ],
    "powder_snow": "PowderSnowBlock",
    "spore_blossom": [
        2,
        13,
        2,
        14,
        16,
        14
    ],
    "hanging_roots": [
        2,
        10,
        2,
        14,
        16,
        14
    ],
    "frogspawn": [
        0,
        0,
        0,
        16,
        1.5,
        16
    ],
    "jungle_sapling": [
        2,
        0,
        2,
        14,
        12,
        14
    ],
    "acacia_sapling": [
        2,
        0,
        2,
        14,
        12,
        14
    ],
    "cherry_sapling": [
        2,
        0,
        2,
        14,
        12,
        14
    ],
    "powered_rail": {
        "shape=ascending_east": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_north": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_south": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_west": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "": [
            0,
            0,
            0,
            16,
            2,
            16
        ]
    },
    "detector_rail": {
        "shape=ascending_east": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_north": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_south": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_west": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "": [
            0,
            0,
            0,
            16,
            2,
            16
        ]
    },
    "wall_torch": {
        "facing=east": [
            0,
            3,
            5.5,
            5,
            13,
            10.5
        ],
        "facing=north": [
            5.5,
            3,
            11,
            10.5,
            13,
            16
        ],
        "facing=south": [
            5.5,
            3,
            0,
            10.5,
            13,
            5
        ],
        "facing=west": [
            11,
            3,
            5.5,
            16,
            13,
            10.5
        ]
    },
    "fire": {
        "combine": {
            "up=true": [
                0,
                15,
                0,
                16,
                16,
                16
            ],
            "west=true": [
                0,
                0,
                0,
                1,
                16,
                16
            ],
            "east=true": [
                15,
                0,
                0,
                16,
                16,
                16
            ],
            "north=true": [
                0,
                0,
                0,
                16,
                16,
                1
            ],
            "south=true": [
                0,
                0,
                15,
                16,
                16,
                16
            ]
        }
    },
    "redstone_wire": {
        "combine": {
            "north=side|up": [
                3,
                0,
                0,
                13,
                1,
                13
            ],
            "south=side|up": [
                3,
                0,
                3,
                13,
                1,
                16
            ],
            "east=side|up": [
                3,
                0,
                3,
                16,
                1,
                13
            ],
            "west=side|up": [
                0,
                0,
                3,
                13,
                1,
                13
            ],
            "north=up": [
                3,
                0,
                0,
                13,
                16,
                1
            ],
            "south=up": [
                3,
                0,
                15,
                13,
                16,
                16
            ],
            "east=up": [
                15,
                0,
                3,
                16,
                16,
                13
            ],
            "west=up": [
                0,
                0,
                3,
                1,
                16,
                13
            ]
        }
    },
    "wheat": {
        "age=0": [
            0,
            0,
            0,
            16,
            2,
            16
        ],
        "age=1": [
            0,
            0,
            0,
            16,
            4,
            16
        ],
        "age=2": [
            0,
            0,
            0,
            16,
            6,
            16
        ],
        "age=3": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "age=4": [
            0,
            0,
            0,
            16,
            10,
            16
        ],
        "age=5": [
            0,
            0,
            0,
            16,
            12,
            16
        ],
        "age=6": [
            0,
            0,
            0,
            16,
            14,
            16
        ],
        "age=7": [
            0,
            0,
            0,
            16,
            16,
            16
        ]
    },
    "oak_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "spruce_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "birch_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "acacia_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "cherry_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "jungle_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "dark_oak_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "mangrove_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "bamboo_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "rail": {
        "shape=ascending_east": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_north": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_south": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_west": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "": [
            0,
            0,
            0,
            16,
            2,
            16
        ]
    },
    "oak_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "spruce_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "birch_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "acacia_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "cherry_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "jungle_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "dark_oak_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "mangrove_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "bamboo_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "oak_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "spruce_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "birch_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "acacia_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "cherry_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "jungle_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "dark_oak_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "crimson_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "warped_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "mangrove_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "bamboo_hanging_sign": [
        3,
        0,
        3,
        13,
        16,
        13
    ],
    "lever": {
        "face=ceiling,facing=east": [
            4,
            0,
            5,
            12,
            6,
            11
        ],
        "face=ceiling,facing=north": [
            5,
            0,
            4,
            11,
            6,
            12
        ],
        "face=ceiling,facing=south": [
            5,
            0,
            4,
            11,
            6,
            12
        ],
        "face=ceiling,facing=west": [
            4,
            0,
            5,
            12,
            6,
            11
        ],
        "face=floor,facing=east": [
            4,
            10,
            5,
            12,
            16,
            11
        ],
        "face=floor,facing=north": [
            5,
            10,
            4,
            11,
            16,
            12
        ],
        "face=floor,facing=south": [
            5,
            10,
            4,
            11,
            16,
            12
        ],
        "face=floor,facing=west": [
            4,
            10,
            5,
            12,
            16,
            11
        ],
        "face=wall,facing=east": [
            0,
            4,
            5,
            6,
            12,
            11
        ],
        "face=wall,facing=north": [
            5,
            4,
            10,
            11,
            12,
            16
        ],
        "face=wall,facing=south": [
            5,
            4,
            0,
            11,
            12,
            6
        ],
        "face=wall,facing=west": [
            10,
            4,
            5,
            16,
            12,
            11
        ]
    },
    "stone_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "oak_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "spruce_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "birch_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "jungle_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "acacia_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "cherry_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "dark_oak_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "mangrove_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "bamboo_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "redstone_torch": [
        6,
        0,
        6,
        10,
        10,
        10
    ],
    "redstone_wall_torch": {
        "facing=east": [
            0,
            3,
            5.5,
            5,
            13,
            10.5
        ],
        "facing=north": [
            5.5,
            3,
            11,
            10.5,
            13,
            16
        ],
        "facing=south": [
            5.5,
            3,
            0,
            10.5,
            13,
            5
        ],
        "facing=west": [
            11,
            3,
            5.5,
            16,
            13,
            10.5
        ]
    },
    "stone_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "sugar_cane": [
        2,
        0,
        2,
        14,
        16,
        14
    ],
    "soul_wall_torch": {
        "facing=east": [
            0,
            3,
            5.5,
            5,
            13,
            10.5
        ],
        "facing=north": [
            5.5,
            3,
            11,
            10.5,
            13,
            16
        ],
        "facing=south": [
            5.5,
            3,
            0,
            10.5,
            13,
            5
        ],
        "facing=west": [
            11,
            3,
            5.5,
            16,
            13,
            10.5
        ]
    },
    "attached_pumpkin_stem": {
        "facing=east": [
            6,
            0,
            6,
            16,
            10,
            10
        ],
        "facing=north": [
            6,
            0,
            0,
            10,
            10,
            10
        ],
        "facing=south": [
            6,
            0,
            6,
            10,
            10,
            16
        ],
        "facing=west": [
            0,
            0,
            6,
            10,
            10,
            10
        ]
    },
    "attached_melon_stem": {
        "facing=east": [
            6,
            0,
            6,
            16,
            10,
            10
        ],
        "facing=north": [
            6,
            0,
            0,
            10,
            10,
            10
        ],
        "facing=south": [
            6,
            0,
            6,
            10,
            10,
            16
        ],
        "facing=west": [
            0,
            0,
            6,
            10,
            10,
            10
        ]
    },
    "pumpkin_stem": {
        "age=0": [
            7,
            0,
            7,
            9,
            2,
            9
        ],
        "age=1": [
            7,
            0,
            7,
            9,
            4,
            9
        ],
        "age=2": [
            7,
            0,
            7,
            9,
            6,
            9
        ],
        "age=3": [
            7,
            0,
            7,
            9,
            8,
            9
        ],
        "age=4": [
            7,
            0,
            7,
            9,
            10,
            9
        ],
        "age=5": [
            7,
            0,
            7,
            9,
            12,
            9
        ],
        "age=6": [
            7,
            0,
            7,
            9,
            14,
            9
        ],
        "age=7": [
            7,
            0,
            7,
            9,
            16,
            9
        ]
    },
    "melon_stem": {
        "age=0": [
            7,
            0,
            7,
            9,
            2,
            9
        ],
        "age=1": [
            7,
            0,
            7,
            9,
            4,
            9
        ],
        "age=2": [
            7,
            0,
            7,
            9,
            6,
            9
        ],
        "age=3": [
            7,
            0,
            7,
            9,
            8,
            9
        ],
        "age=4": [
            7,
            0,
            7,
            9,
            10,
            9
        ],
        "age=5": [
            7,
            0,
            7,
            9,
            12,
            9
        ],
        "age=6": [
            7,
            0,
            7,
            9,
            14,
            9
        ],
        "age=7": [
            7,
            0,
            7,
            9,
            16,
            9
        ]
    },
    "vine": {
        "combine": {
            "up=true": [
                0,
                15,
                0,
                16,
                16,
                16
            ],
            "west=true": [
                0,
                0,
                0,
                1,
                16,
                16
            ],
            "east=true": [
                15,
                0,
                0,
                16,
                16,
                16
            ],
            "north=true": [
                0,
                0,
                0,
                16,
                16,
                1
            ],
            "south=true": [
                0,
                0,
                15,
                16,
                16,
                16
            ]
        }
    },
    "glow_lichen": {
        "combine": {
            "up=true": [
                0,
                15,
                0,
                16,
                16,
                16
            ],
            "west=true": [
                0,
                0,
                0,
                1,
                16,
                16
            ],
            "east=true": [
                15,
                0,
                0,
                16,
                16,
                16
            ],
            "north=true": [
                0,
                0,
                0,
                16,
                16,
                1
            ],
            "south=true": [
                0,
                0,
                15,
                16,
                16,
                16
            ]
        }
    },
    "nether_wart": {
        "age=0": [
            0,
            0,
            0,
            16,
            5,
            16
        ],
        "age=1": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "age=2": [
            0,
            0,
            0,
            16,
            11,
            16
        ],
        "age=3": [
            0,
            0,
            0,
            16,
            14,
            16
        ]
    },
    "tripwire_hook": {
        "facing=east": [
            0,
            0,
            5,
            6,
            10,
            11
        ],
        "facing=north": [
            5,
            0,
            10,
            11,
            10,
            16
        ],
        "facing=south": [
            5,
            0,
            0,
            11,
            10,
            6
        ],
        "facing=west": [
            10,
            0,
            5,
            16,
            10,
            11
        ]
    },
    "tripwire": {
        "attached=true": [
            0,
            1,
            0,
            16,
            2.5,
            16
        ],
        "attached=false": [
            0,
            0,
            0,
            16,
            8,
            16
        ]
    },
    "carrots": {
        "age=0": [
            0,
            0,
            0,
            16,
            2,
            16
        ],
        "age=1": [
            0,
            0,
            0,
            16,
            3,
            16
        ],
        "age=2": [
            0,
            0,
            0,
            16,
            4,
            16
        ],
        "age=3": [
            0,
            0,
            0,
            16,
            5,
            16
        ],
        "age=4": [
            0,
            0,
            0,
            16,
            6,
            16
        ],
        "age=5": [
            0,
            0,
            0,
            16,
            7,
            16
        ],
        "age=6": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "age=7": [
            0,
            0,
            0,
            16,
            9,
            16
        ]
    },
    "potatoes": {
        "age=0": [
            0,
            0,
            0,
            16,
            2,
            16
        ],
        "age=1": [
            0,
            0,
            0,
            16,
            3,
            16
        ],
        "age=2": [
            0,
            0,
            0,
            16,
            4,
            16
        ],
        "age=3": [
            0,
            0,
            0,
            16,
            5,
            16
        ],
        "age=4": [
            0,
            0,
            0,
            16,
            6,
            16
        ],
        "age=5": [
            0,
            0,
            0,
            16,
            7,
            16
        ],
        "age=6": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "age=7": [
            0,
            0,
            0,
            16,
            9,
            16
        ]
    },
    "oak_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "spruce_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "birch_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "jungle_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "acacia_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "cherry_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "dark_oak_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "mangrove_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "bamboo_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "light_weighted_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "heavy_weighted_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "activator_rail": {
        "shape=ascending_east": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_north": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_south": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "shape=ascending_west": [
            0,
            0,
            0,
            16,
            8,
            16
        ],
        "": [
            0,
            0,
            0,
            16,
            2,
            16
        ]
    },
    "light": [],
    "sunflower": [
        0,
        0,
        0,
        16,
        16,
        16
    ],
    "rose_bush": [
        0,
        0,
        0,
        16,
        16,
        16
    ],
    "peony": [
        0,
        0,
        0,
        16,
        16,
        16
    ],
    "large_fern": [
        0,
        0,
        0,
        16,
        16,
        16
    ],
    "white_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "orange_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "magenta_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "light_blue_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "yellow_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "lime_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "pink_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "gray_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "light_gray_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "cyan_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "purple_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "blue_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "brown_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "green_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "red_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "black_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "white_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "orange_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "magenta_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "light_blue_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "yellow_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "lime_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "pink_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "gray_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "light_gray_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "cyan_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "purple_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "blue_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "brown_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "green_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "red_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "black_wall_banner": {
        "facing=north": [
            0,
            0,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            0,
            0,
            16,
            12.5,
            2
        ],
        "facing=west": [
            14,
            0,
            0,
            16,
            12.5,
            16
        ],
        "facing=east": [
            0,
            0,
            0,
            2,
            12.5,
            16
        ]
    },
    "torchflower_crop": {
        "age=0": [
            5,
            0,
            5,
            11,
            6,
            11
        ],
        "age=1": [
            5,
            0,
            5,
            11,
            10,
            11
        ]
    },
    "pitcher_plant": [
        0,
        0,
        0,
        16,
        16,
        16
    ],
    "beetroots": {
        "age=0": [
            0,
            0,
            0,
            16,
            2,
            16
        ],
        "age=1": [
            0,
            0,
            0,
            16,
            4,
            16
        ],
        "age=2": [
            0,
            0,
            0,
            16,
            6,
            16
        ],
        "age=3": [
            0,
            0,
            0,
            16,
            8,
            16
        ]
    },
    "kelp": [
        0,
        0,
        0,
        16,
        9,
        16
    ],
    "dead_tube_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "dead_brain_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "dead_bubble_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "dead_fire_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "dead_horn_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "tube_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "brain_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "bubble_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "fire_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "horn_coral_wall_fan": {
        "facing=north": [
            0,
            4,
            5,
            16,
            12,
            16
        ],
        "facing=south": [
            0,
            4,
            0,
            16,
            12,
            11
        ],
        "facing=west": [
            5,
            4,
            0,
            16,
            12,
            16
        ],
        "facing=east": [
            0,
            4,
            0,
            11,
            12,
            16
        ]
    },
    "sweet_berry_bush": {
        "age=0": [
            3,
            0,
            3,
            13,
            8,
            13
        ],
        "age=1": [
            1,
            0,
            1,
            15,
            16,
            15
        ],
        "age=2": [
            1,
            0,
            1,
            15,
            16,
            15
        ],
        "": [
            0,
            0,
            0,
            16,
            16,
            16
        ]
    },
    "weeping_vines": [
        4,
        9,
        4,
        12,
        16,
        12
    ],
    "twisting_vines": [
        4,
        0,
        4,
        12,
        15,
        12
    ],
    "crimson_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "warped_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "crimson_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "warped_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "crimson_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "warped_sign": [
        4,
        0,
        4,
        12,
        16,
        12
    ],
    "crimson_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "warped_wall_sign": {
        "facing=north": [
            0,
            4.5,
            14,
            16,
            12.5,
            16
        ],
        "facing=south": [
            0,
            4.5,
            0,
            16,
            12.5,
            2
        ],
        "facing=east": [
            0,
            4.5,
            0,
            2,
            12.5,
            16
        ],
        "facing=west": [
            14,
            4.5,
            0,
            16,
            12.5,
            16
        ]
    },
    "polished_blackstone_pressure_plate": {
        "powered=false": [
            1,
            0,
            1,
            15,
            1,
            15
        ],
        "powered=true": [
            1,
            0,
            1,
            15,
            0.5,
            15
        ]
    },
    "polished_blackstone_button": {
        "face=floor,facing=north,powered=false": [
            6,
            0,
            5,
            10,
            2,
            11
        ],
        "face=floor,facing=north,powered=true": [
            6,
            0,
            5,
            10,
            1,
            11
        ],
        "face=floor,facing=south,powered=false": [
            5,
            0,
            6,
            11,
            2,
            10
        ],
        "face=floor,facing=south,powered=true": [
            5,
            0,
            6,
            11,
            1,
            10
        ],
        "face=floor,facing=west,powered=false": [
            14,
            0,
            5,
            16,
            2,
            11
        ],
        "face=floor,facing=west,powered=true": [
            15,
            0,
            5,
            16,
            1,
            11
        ],
        "face=floor,facing=east,powered=false": [
            0,
            0,
            5,
            2,
            2,
            11
        ],
        "face=floor,facing=east,powered=true": [
            0,
            0,
            5,
            1,
            1,
            11
        ],
        "face=wall,facing=north,powered=false": [
            5,
            6,
            14,
            11,
            10,
            16
        ],
        "face=wall,facing=north,powered=true": [
            5,
            6,
            15,
            11,
            10,
            16
        ],
        "face=wall,facing=south,powered=false": [
            5,
            6,
            0,
            11,
            10,
            2
        ],
        "face=wall,facing=south,powered=true": [
            5,
            6,
            0,
            11,
            10,
            1
        ],
        "face=wall,facing=west,powered=false": [
            14,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=west,powered=true": [
            15,
            6,
            5,
            16,
            10,
            11
        ],
        "face=wall,facing=east,powered=false": [
            0,
            6,
            5,
            2,
            10,
            11
        ],
        "face=wall,facing=east,powered=true": [
            0,
            6,
            5,
            1,
            10,
            11
        ],
        "face=ceiling,facing=north,powered=false": [
            6,
            14,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=north,powered=true": [
            6,
            15,
            5,
            10,
            16,
            11
        ],
        "face=ceiling,facing=south,powered=false": [
            5,
            14,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=south,powered=true": [
            5,
            15,
            6,
            11,
            16,
            10
        ],
        "face=ceiling,facing=west,powered=false": [
            14,
            14,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=west,powered=true": [
            15,
            15,
            5,
            16,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=false": [
            0,
            14,
            5,
            2,
            16,
            11
        ],
        "face=ceiling,facing=east,powered=true": [
            0,
            15,
            5,
            1,
            16,
            11
        ]
    },
    "sculk_vein": {
        "combine": {
            "up=true": [
                0,
                15,
                0,
                16,
                16,
                16
            ],
            "west=true": [
                0,
                0,
                0,
                1,
                16,
                16
            ],
            "east=true": [
                15,
                0,
                0,
                16,
                16,
                16
            ],
            "north=true": [
                0,
                0,
                0,
                16,
                16,
                1
            ],
            "south=true": [
                0,
                0,
                15,
                16,
                16,
                16
            ]
        }
    },
    "cave_vines": [
        1,
        0,
        1,
        15,
        16,
        15
    ],
    "pink_petals": "PinkPetalsBlock",
    "big_dripleaf_stem": {
        "facing=north": [
            5,
            0,
            9,
            11,
            16,
            15
        ],
        "facing=south": [
            5,
            0,
            1,
            11,
            16,
            7
        ],
        "facing=east": [
            1,
            0,
            5,
            7,
            16,
            11
        ],
        "facing=west": [
            9,
            0,
            5,
            15,
            16,
            11
        ]
    },
    "small_dripleaf": [
        2,
        0,
        2,
        14,
        13,
        14
    ]
}
